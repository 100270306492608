.dropdown-menu{
  position: relative;
  z-index: 99;
}

.dropdown-menu li {
  background: black;
  cursor: pointer;
}

.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 10px 20px;
}
