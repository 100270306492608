@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://db.onlinewebfonts.com/c/0582f222152bcd8e185a6fd56a1eb05c?family=Brush+455+W03+Rg);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.green-color {
  background-color: #017128;
}

.orange-color {
  background-color: #ffa800;
}

.text-green {
  color: #017128;
}

.text-orange {
  color: #ffa800;
}

* {
  transition: 0.4s ease;
}

.h-200 {
  height: 35rem;
  width: 100%;
}

.active {
  color: #017128;
  font-weight: bold;
}

@font-face {
  font-family: "cargiona";
  src: url(../public/assets/fonts/cargiona/Cargiona-SemiBold.otf);
}

.cargiona {
  font-family: "cargiona";
}

@font-face {
  font-family: "nimbus-roman";
  src: url(../public/assets/fonts/nimbus-roman-no9-l/NimbusRomNo9L-Reg.otf);
}

.nimbus-roman {
  font-family: "nimbus-roman";
}

@font-face {
  font-family: "Poppins";
  src: url(../public/assets/fonts/Poppins/Poppins-Regular.ttf);
}

.poppins {
  font-family: "Poppins";
}

